import React, { FC, PropsWithChildren } from 'react';
import { IntlProvider } from 'react-intl';

import merge from 'lodash/merge';

import { flattenMessages } from '../helpers';

import error404 from './404';
import audits from './audits';
import auth from './auth';
import burn from './burn';
import card from './card';
import claim from './claim';
import create from './create';
import ethStaking from './eth-staking';
import farms from './farms';
import global from './global';
import history from './history';
import home from './home';
import lounge from './lounge';
import pools from './pools';
import staking from './staking';
import swap from './swap';
import usdcStaking from './usdc-staking';
import usdtStaking from './usdt-staking';
import vote from './vote';

const DEFAULT_LOCALE = 'en';
export const appLocales = {
  en: {
    path: 'en',
    locale: 'English',
    default: true,
  },
  /*
  zh: {
    path: 'zh',
    locale: '中文',
  },
    ja: {
      path: 'ja',
      locale: '日本語',
    },
  ko: {
    path: 'ko',
    locale: '한국어',
  },
  ru: {
    path: 'ru',
    locale: 'Русский',
  },
  es: {
    path: 'es',
    locale: 'Español',
  },
  fr: {
    path: 'fr',
    locale: 'Français',
  },
  */
};

const messagesMap: {
  [locale: string]: Record<string, unknown>;
} = {
  audits,
  auth,
  claim,
  create,
  404: error404,
  'eth-staking': ethStaking,
  farms,
  global,
  history,
  home,
  lounge,
  pools,
  staking,
  swap,
  'usdc-staking': usdcStaking,
  'usdt-staking': usdtStaking,
  vote,
  burn,
  card,
  restricted: card,
};

interface Props {
  localeDir: string;
  locale: string;
  netlify?: Record<string, unknown>;
}

const Intl: FC<PropsWithChildren<Props>> = props => {
  const { localeDir, children, locale, netlify } = props;
  // console.log(localeDir, locale);
  const splitLocaleDir = localeDir.split('/').reduce((prev: string[], curr) => {
    if (curr === '') return prev;
    return [...prev, curr];
  }, []);

  // console.log(splitLocaleDir);
  let pageName = '404';

  Object.keys(messagesMap).forEach(value => {
    if (splitLocaleDir.includes(value)) pageName = value;
  });

  pageName = splitLocaleDir[splitLocaleDir.length - 1];

  if (splitLocaleDir.length === 0) pageName = 'home';
  if (splitLocaleDir[0] === 'pools') {
    if (splitLocaleDir[1] === 'create') {
      pageName = 'create';
    } else {
      pageName = 'pools';
    }
  }
  if (splitLocaleDir[0] === 'farms') {
    pageName = 'farms';
  }
  if (splitLocaleDir[0] === 'staking') {
    if (splitLocaleDir[2] && !splitLocaleDir[2].includes('verse')) {
      pageName = `${splitLocaleDir[2] || 'eth'}-staking`;
    } else {
      pageName = 'staking';
    }
  }
  if (splitLocaleDir[0] === 'swap') {
    pageName = 'swap';
  }
  if (
    splitLocaleDir[0] === 'history' &&
    splitLocaleDir[1]?.match(/^(eth\b|sbch\b|seth\b|heth\b|cex\b)/gim)
  ) {
    pageName = 'history';
  }

  if (
    splitLocaleDir.length === 1 &&
    Object.keys(appLocales).includes(splitLocaleDir[0])
  )
    pageName = 'home';
  /* locale === DEFAULT_LOCALE && splitLocaleDir.length === 0
      ? 'home'
      : splitLocaleDir[0]; */

  // console.log(pageName);
  const locales = messagesMap[pageName] || messagesMap['404'];
  // console.log(locales[locale]);
  const messages = netlify
    ? merge({}, locales[DEFAULT_LOCALE], {
        ...flattenMessages(global, 'global'),
        ...flattenMessages(netlify, pageName),
      })
    : merge(
        {},
        locales[DEFAULT_LOCALE],
        locales[locale],
        messagesMap['404'][locale],
      );

  return (
    <IntlProvider
      locale={locale}
      defaultLocale={DEFAULT_LOCALE}
      messages={messages}
    >
      {children}
    </IntlProvider>
  );
};

export default Intl;
