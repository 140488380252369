const verseWbtcStakingData = {
  contractAddress: '0xEB80fe51D5334704Dc0859840517fe45aAbcCee6',
  stakingToken: {
    // ITickerObject
    token: '0x249cA82617eC3DfB2589c4c17ab7EC9765350a18',
    decimals: 18,
    abbr: 'VERSE',
    value: 'VERSE',
    blockchain: 'ETH',
    label: 'Verse',
    ticker: 'verse',
    explorer: '',
    protocol: 'ERC_20_PROTOCOL',
    icon: 'https://markets.bitcoin.com/images/coins/verse.png',
  },
  rewardToken: {
    // ITickerObject
    token: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
    decimals: 8,
    abbr: 'WBTC',
    value: 'WBTC',
    blockchain: 'ETH',
    label: 'Wrapped BTC',
    ticker: 'wbtc',
    explorer: 'https://etherscan.io/tx/',
    protocol: 'ERC_20_PROTOCOL',
    icon: 'https://markets.bitcoin.com/images/coins/3717.png',
  },
  pair: {
    id: '0x249cA82617eC3DfB2589c4c17ab7EC9765350a18',
    name: 'VERSE',
    token0: {
      name: 'Verse',
      symbol: 'VERSE',
      id: '0x249cA82617eC3DfB2589c4c17ab7EC9765350a18',
      decimals: 18,
    },
  },
  singleSided: true,
  customPath: 'verse-wbtc',
};

export default verseWbtcStakingData;
