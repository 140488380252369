import verseStakingEthData from './verseStakingEthData';
import verseWbtcStakingData from './verseWbtcStakingData';

const farmsEthList = [
  {
    contractAddress: '0xDED0C22aCd80e7a4bd6eC91ced451Fc83f04cAB2',
    stakingToken: {
      // ITickerObject
      token: '0x845C0179060362f071FF5C7f1D2703617a480F3e',
      decimals: 18,
      abbr: 'VERSE-X',
      value: 'VERSE-X',
      blockchain: 'ETH',
      label: 'Verse-X Token',
      ticker: 'verse-x',
      explorer: '',
      protocol: 'ERC_20_PROTOCOL',
    },
    rewardToken: {
      // ITickerObject
      token: '0x249cA82617eC3DfB2589c4c17ab7EC9765350a18',
      decimals: 18,
      abbr: 'VERSE',
      value: 'VERSE',
      blockchain: 'ETH',
      label: 'Verse',
      ticker: 'verse',
      explorer: 'https://etherscan.io/tx/',
      protocol: 'ERC_20_PROTOCOL',
      icon: 'https://markets.bitcoin.com/images/coins/verse.png',
    },
    pair: {
      id: '0x845C0179060362f071FF5C7f1D2703617a480F3e',
      name: 'VERSE/ETH',
      token0: {
        name: 'Verse',
        symbol: 'VERSE',
        id: '0x249cA82617eC3DfB2589c4c17ab7EC9765350a18',
        decimals: 18,
      },
      token1: {
        name: 'Ethereum',
        symbol: 'ETH',
        id: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
        decimals: 18,
      },
    },
  },
  {
    contractAddress: '0xdd5a9EEc299b74B2db2D3430608c1c5A8d9598eb',
    stakingToken: {
      // ITickerObject
      token: '0x682831244b0E97946ABC52Cb1893Cce398De3A35',
      decimals: 18,
      abbr: 'VERSE-X',
      value: 'VERSE-X',
      blockchain: 'ETH',
      label: 'Verse-X Token',
      ticker: 'verse-x',
      explorer: '',
      protocol: 'ERC_20_PROTOCOL',
    },
    rewardToken: {
      // ITickerObject
      token: '0x249cA82617eC3DfB2589c4c17ab7EC9765350a18',
      decimals: 18,
      abbr: 'VERSE',
      value: 'VERSE',
      blockchain: 'ETH',
      label: 'Verse',
      ticker: 'verse',
      explorer: 'https://etherscan.io/tx/',
      protocol: 'ERC_20_PROTOCOL',
      icon: 'https://markets.bitcoin.com/images/coins/verse.png',
    },
    pair: {
      id: '0x682831244b0E97946ABC52Cb1893Cce398De3A35',
      name: 'ETH/DAI',
      token0: {
        name: 'Ethereum',
        symbol: 'ETH',
        id: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
        decimals: 18,
      },
      token1: {
        name: 'DAI',
        symbol: 'DAI',
        id: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
        decimals: 18,
      },
    },
  },
  {
    contractAddress: '0xC5aF93687088C28DA839371F3249dF757B219aA8',
    stakingToken: {
      // ITickerObject
      token: '0x362DdD14F69924ebBF9066dDD2AE7C171cF6d098',
      decimals: 18,
      abbr: 'VERSE-X',
      value: 'VERSE-X',
      blockchain: 'ETH',
      label: 'Verse-X Token',
      ticker: 'verse-x',
      explorer: '',
      protocol: 'ERC_20_PROTOCOL',
    },
    rewardToken: {
      // ITickerObject
      token: '0x249cA82617eC3DfB2589c4c17ab7EC9765350a18',
      decimals: 18,
      abbr: 'VERSE',
      value: 'VERSE',
      blockchain: 'ETH',
      label: 'Verse',
      ticker: 'verse',
      explorer: 'https://etherscan.io/tx/',
      protocol: 'ERC_20_PROTOCOL',
      icon: 'https://markets.bitcoin.com/images/coins/verse.png',
    },
    pair: {
      id: '0x362DdD14F69924ebBF9066dDD2AE7C171cF6d098',
      name: 'ETH/USDT',
      token0: {
        name: 'Ethereum',
        symbol: 'ETH',
        id: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
        decimals: 18,
      },
      token1: {
        name: 'USDT',
        symbol: 'USDT',
        id: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
        decimals: 6,
      },
    },
  },
  {
    contractAddress: '0x4E1F1206f2B9a651EcF2D49C5d33761861D4910C',
    stakingToken: {
      // ITickerObject
      token: '0x6E1fbeeABA87BAe1100d95f8340dc27aD7C8427b',
      decimals: 18,
      abbr: 'VERSE-X',
      value: 'VERSE-X',
      blockchain: 'ETH',
      label: 'Verse-X Token',
      ticker: 'verse-x',
      explorer: '',
      protocol: 'ERC_20_PROTOCOL',
    },
    rewardToken: {
      // ITickerObject
      token: '0x249cA82617eC3DfB2589c4c17ab7EC9765350a18',
      decimals: 18,
      abbr: 'VERSE',
      value: 'VERSE',
      blockchain: 'ETH',
      label: 'Verse',
      ticker: 'verse',
      explorer: 'https://etherscan.io/tx/',
      protocol: 'ERC_20_PROTOCOL',
      icon: 'https://markets.bitcoin.com/images/coins/verse.png',
    },
    pair: {
      id: '0x6E1fbeeABA87BAe1100d95f8340dc27aD7C8427b',
      name: 'ETH/USDC',
      token0: {
        name: 'Ethereum',
        symbol: 'ETH',
        id: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
        decimals: 18,
      },
      token1: {
        name: 'USDC',
        symbol: 'USDC',
        id: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
        decimals: 6,
      },
    },
  },
  {
    contractAddress: '0x4efff28192029bdb1Ac027c53674721875DA6B10',
    stakingToken: {
      // ITickerObject
      token: '0xeaCADc656c9394fb09af25AeBc0897fDfFe484A1',
      decimals: 18,
      abbr: 'VERSE-X',
      value: 'VERSE-X',
      blockchain: 'ETH',
      label: 'Verse-X Token',
      ticker: 'verse-x',
      explorer: '',
      protocol: 'ERC_20_PROTOCOL',
    },
    rewardToken: {
      // ITickerObject
      token: '0x249cA82617eC3DfB2589c4c17ab7EC9765350a18',
      decimals: 18,
      abbr: 'VERSE',
      value: 'VERSE',
      blockchain: 'ETH',
      label: 'Verse',
      ticker: 'verse',
      explorer: 'https://etherscan.io/tx/',
      protocol: 'ERC_20_PROTOCOL',
      icon: 'https://markets.bitcoin.com/images/coins/verse.png',
    },
    pair: {
      id: '0xeaCADc656c9394fb09af25AeBc0897fDfFe484A1',
      name: 'WBTC/ETH',
      token0: {
        name: 'Wrapped BTC',
        symbol: 'WBTC',
        id: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
        decimals: 8,
      },
      token1: {
        name: 'Ethereum',
        symbol: 'ETH',
        id: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
        decimals: 18,
      },
    },
  },
  {
    contractAddress: '0x4BA48Df24008429aE9140A01e0D002F5FA6A125D',
    stakingToken: {
      // ITickerObject
      token: '0xF3f82bC165a83425e81b8f2858D786E41ab21671',
      decimals: 18,
      abbr: 'VERSE-X',
      value: 'VERSE-X',
      blockchain: 'ETH',
      label: 'Verse-X Token',
      ticker: 'verse-x',
      explorer: '',
      protocol: 'ERC_20_PROTOCOL',
    },
    rewardToken: {
      // ITickerObject
      token: '0x249cA82617eC3DfB2589c4c17ab7EC9765350a18',
      decimals: 18,
      abbr: 'VERSE',
      value: 'VERSE',
      blockchain: 'ETH',
      label: 'Verse',
      ticker: 'verse',
      explorer: 'https://etherscan.io/tx/',
      protocol: 'ERC_20_PROTOCOL',
      icon: 'https://markets.bitcoin.com/images/coins/verse.png',
    },
    pair: {
      id: '0xF3f82bC165a83425e81b8f2858D786E41ab21671',
      name: 'WBTC/USDC',
      token0: {
        name: 'Wrapped BTC',
        symbol: 'WBTC',
        id: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
        decimals: 8,
      },
      token1: {
        name: 'USD Coin',
        symbol: 'USDC',
        id: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
        decimals: 18,
      },
    },
  },
  {
    contractAddress: '0x17BDCEEC80d3506e384dB09E5D5696EDf70605Ef',
    stakingToken: {
      // ITickerObject
      token: '0x33d15C3c82E09D438d671A04c27E2cc8E1c9D134',
      decimals: 18,
      abbr: 'VERSE-X',
      value: 'VERSE-X',
      blockchain: 'ETH',
      label: 'Verse-X Token',
      ticker: 'verse-x',
      explorer: '',
      protocol: 'ERC_20_PROTOCOL',
    },
    rewardToken: {
      // ITickerObject
      token: '0x249cA82617eC3DfB2589c4c17ab7EC9765350a18',
      decimals: 18,
      abbr: 'VERSE',
      value: 'VERSE',
      blockchain: 'ETH',
      label: 'Verse',
      ticker: 'verse',
      explorer: 'https://etherscan.io/tx/',
      protocol: 'ERC_20_PROTOCOL',
      icon: 'https://markets.bitcoin.com/images/coins/verse.png',
    },
    pair: {
      id: '0x33d15C3c82E09D438d671A04c27E2cc8E1c9D134',
      name: 'USDC/HAPI',
      token0: {
        name: 'USD Coin',
        symbol: 'USDC',
        id: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
        decimals: 18,
      },
      token1: {
        name: 'HAPI',
        symbol: 'HAPI',
        id: '0xD9c2D319Cd7e6177336b0a9c93c21cb48d84Fb54',
        decimals: 18,
      },
    },
  },
  {
    contractAddress: '0x3E587eb9E7843999c4BC67719c41B80425563aF8',
    stakingToken: {
      token: '0x294fff8FbfE37dA6FFD410b4cA370b92AE853a9B',
      decimals: 18,
      abbr: 'VERSE-X',
      value: 'VERSE-X',
      blockchain: 'ETH',
      label: 'Verse-X Token',
      ticker: 'verse-x',
      explorer: '',
      protocol: 'ERC_20_PROTOCOL',
    },
    rewardToken: {
      token: '0xdb85f6685950E285b1E611037BEBe5B34e2B7d78',
      decimals: 18,
      abbr: 'WZANO',
      value: 'WZANO',
      blockchain: 'ETH',
      label: 'WZANO',
      ticker: 'wzano',
      explorer: 'https://etherscan.io/tx/',
      protocol: 'ERC_20_PROTOCOL',
      icon: 'https://markets.bitcoin.com/images/coins/8370.png',
    },
    pair: {
      id: '0x294fff8FbfE37dA6FFD410b4cA370b92AE853a9B',
      name: 'USDT/WZANO',
      token0: {
        name: 'USDT',
        symbol: 'USDT',
        id: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
        decimals: 6,
      },
      token1: {
        name: 'WZANO',
        symbol: 'WZANO',
        id: '0xdb85f6685950E285b1E611037BEBe5B34e2B7d78',
        decimals: 18,
      },
    },
  },
  {
    /* OLD CONTRACT */
    contractAddress: '0xa51f4C17bb902fF996Ccd766cE072C7CE30E95F4',
    stakingToken: {
      token: '0x294fff8FbfE37dA6FFD410b4cA370b92AE853a9B',
      decimals: 18,
      abbr: 'VERSE-X',
      value: 'VERSE-X',
      blockchain: 'ETH',
      label: 'Verse-X Token',
      ticker: 'verse-x',
      explorer: '',
      protocol: 'ERC_20_PROTOCOL',
    },
    rewardToken: {
      token: '0x249cA82617eC3DfB2589c4c17ab7EC9765350a18',
      decimals: 18,
      abbr: 'VERSE',
      value: 'VERSE',
      blockchain: 'ETH',
      label: 'Verse',
      ticker: 'verse',
      explorer: 'https://etherscan.io/tx/',
      protocol: 'ERC_20_PROTOCOL',
      icon: 'https://markets.bitcoin.com/images/coins/verse.png',
    },
    pair: {
      id: '0x294fff8FbfE37dA6FFD410b4cA370b92AE853a9B',
      name: 'USDT/WZANO',
      token0: {
        name: 'USDT',
        symbol: 'USDT',
        id: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
        decimals: 6,
      },
      token1: {
        name: 'WZANO',
        symbol: 'WZANO',
        id: '0xdb85f6685950E285b1E611037BEBe5B34e2B7d78',
        decimals: 18,
      },
    },
    hidden: true,
  },
  {
    // Old farm contract
    contractAddress: '0x8295e4b84335Af685E596DbcD76bbbbADbF88B01',
    stakingToken: {
      // ITickerObject
      token: '0x845C0179060362f071FF5C7f1D2703617a480F3e',
      decimals: 18,
      abbr: 'VERSE-X',
      value: 'VERSE-X',
      blockchain: 'ETH',
      label: 'Verse-X Token',
      ticker: 'verse-x',
      explorer: '',
      protocol: 'ERC_20_PROTOCOL',
    },
    rewardToken: {
      // ITickerObject
      token: '0x249cA82617eC3DfB2589c4c17ab7EC9765350a18',
      decimals: 18,
      abbr: 'VERSE',
      value: 'VERSE',
      blockchain: 'ETH',
      label: 'Verse',
      ticker: 'verse',
      explorer: 'https://etherscan.io/tx/',
      protocol: 'ERC_20_PROTOCOL',
      icon: 'https://markets.bitcoin.com/images/coins/verse.png',
    },
    pair: {
      id: '0x845C0179060362f071FF5C7f1D2703617a480F3e',
      name: 'VERSE/ETH',
      token0: {
        name: 'Verse',
        symbol: 'VERSE',
        id: '0x249cA82617eC3DfB2589c4c17ab7EC9765350a18',
        decimals: 18,
      },
      token1: {
        name: 'Ethereum',
        symbol: 'ETH',
        id: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
        decimals: 18,
      },
    },
    hidden: true,
  },
  verseStakingEthData,
  verseWbtcStakingData,
];

export default farmsEthList;
